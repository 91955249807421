<template>
	<a-card
		:body-style="{ padding: '1px 0 2px 0', height: '100%' }"
		class="order-card"
		:tab-list="tabList"
		:active-tab-key="selectedTab"
		size="small"
		@tabChange="changeFilter"
	>
		<div class="body">
			<a-row v-for="group in ordersGroups" :key="group.date">
				<p class="date-tag">
					<a-tag color="#bfbfbf">
						{{ group.date }}
					</a-tag>
				</p>
				<OrdersList :orders="group.orders" style="padding: 0px 5px;" />
			</a-row>
			<OrdersList
				v-if="loading"
				:orders="[]"
				:loading="true"
				style="padding: 0px 5px;"
			/>
			<p v-else-if="filteredOrders.length === 0" class="empty-list">
				Nenhum pedido a ser listado
			</p>
			<InfiniteLoading
				slot="append"
				ref="infiniteLoading"
				force-use-infinite-wrapper=".body__body-wrapper"
				@infinite="loadMore"
			>
				<div slot="spinner"></div>
				<div slot="no-more"></div>
				<div slot="no-results"></div>
			</InfiniteLoading>
		</div>
		<div slot="tabBarExtraContent">
			<a-range-picker
				:locale="locale"
				:ranges="{
					Hoje: [moment(), moment()],
					'Este mês': [moment().startOf('month'), moment()],
					'Este ano': [moment().startOf('year'), moment()],
				}"
				format="DD/MM/YYYY"
				:default-value="dates"
				:disabled-date="disableFutureDates"
				@change="changeDates"
			>
				<a-icon slot="suffixIcon" type="calendar" />
			</a-range-picker>
		</div>
	</a-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { OrdersList } from '../../ordersPage/components'
import InfiniteLoading from 'vue-infinite-loading'
import locale from 'ant-design-vue/es/date-picker/locale/pt_BR'

const tabList = [
	{
		key: 'HISTORY',
		tab: 'Todos',
	},
	{
		key: 'FINISHED',
		tab: 'Finalizados',
	},
	{
		key: 'CANCELED',
		tab: 'Cancelados',
	},
]

export default {
	name: 'OrdersContainer',
	components: {
		OrdersList,
		InfiniteLoading,
	},
	data() {
		return {
			tabList,
			loading: false,
			currentPage: 1,
			filteredOrders: [],
			locale,
		}
	},
	computed: {
		...mapGetters({
			isMerchantActive: 'merchants/isMerchantActive',
			isAuthorized: 'merchants/isAuthorized',
			selectedMerchantId: 'merchants/selectedMerchantId',
			orders: 'orders/history',
			selectedTab: 'ux/historySelectedTab',
			dates: 'ux/historyRangeDates',
		}),
		ordersGroups: function () {
			const groups = this.filteredOrders.reduce((groups, order) => {
				const date = order.created_at.split('T')[0]

				if (!groups[date]) {
					groups[date] = []
				}

				groups[date].push(order)
				return groups
			}, {})

			return Object.keys(groups).map((date) => {
				return {
					date: this.moment(date).format('DD/MM/YYYY'),
					orders: groups[date],
				}
			})
		},
	},
	watch: {
		async dates() {
			this.currentPage = 1
			this.filteredOrders = []
			this.$refs.infiniteLoading.stateChanger.reset()
		},
		orders(values) {
			this.filteredOrders = values || []
		},
	},
	methods: {
		...mapActions('orders', ['loadMoreOrders']),
		...mapMutations('ux', [
			'setHistorySelectedTab',
			'setHistoryRangeDates',
		]),
		async loadData(payload, $state) {
			if (!this.isAuthorized || !this.isMerchantActive) return

			try {
				this.loading = true
				const next = await this.loadMoreOrders(payload)

				if (next !== null) {
					this.currentPage++
					$state.loaded()
				} else {
					$state.complete()
				}
			} catch (error) {
				//
			} finally {
				this.loading = false
			}
		},
		loadMore($state) {
			const payload = {
				merchantId: this.selectedMerchantId,
				params: {
					order_type: this.selectedTab,
					page: this.currentPage,
					page_size: 10,
				},
			}

			if (this.dates.length > 0) {
				payload.params.date_initial = this.dates[0].format('YYYY/MM/DD')
				payload.params.date_final = this.dates[1].format('YYYY/MM/DD')
			}

			this.loadData(payload, $state)
		},
		changeDates(dates) {
			this.setHistoryRangeDates(dates)
		},
		changeFilter(key) {
			this.setHistorySelectedTab(key)
			this.currentPage = 1
			this.filteredOrders = []
			this.$refs.infiniteLoading.stateChanger.reset()
		},
		disableFutureDates(current) {
			return current > this.moment().endOf('day')
		},
	},
}
</script>

<style lang="less" scoped>
@import url('@/config/globalStyles');

.body {
	.date-tag {
		padding: 8px 0;
		background-color: #f8f8f8;
		display: flex;
		justify-content: center;
	}
}
</style>
