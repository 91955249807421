<template>
	<div>
		<PageHeader :title="$route.meta.title" />
		<OrdersContainer ref="ordersContainer" />
	</div>
</template>

<script>
import { PageHeader } from '../../components'
import { OrdersContainer } from './components'
import mixin from '@/utils/mixinPages'

export default {
	name: 'HistoryOrdersPage',
	components: {
		PageHeader,
		OrdersContainer,
	},
	mixins: [mixin],
}
</script>
